import axios from 'axios';
import { toast } from 'react-toastify';
import { Roles } from '../enums/Roles';
// import { chatListItems, messages, autoresponse, agentAttendanceData } from './FakeData';
// messages.reverse();

const STAGE = (process.env.REACT_APP_ENV==='production') ? 'production' : 'staging';
const API_BASE_URL = `${process.env.REACT_APP_API_URL}/${STAGE}/api/cs-wa`;
const APIService = { STAGE };

APIService.getAuthToken = function () {
	// gets auth_token from cookies
	const allCookies = document.cookie.split(';');
	let cookies = {};
	for (let cookie of allCookies) {
		const [name, value] = cookie.trim().split("=");
		cookies[name] = value;
	}
	return cookies['auth_token'];
}

APIService.authorizeAgent = async function () {
	// will return id, name, email, phone, role - after verifying cookie[auth_token]
	try {
		const AUTH_API_BASE_URL = (STAGE==='production') ?
			'https://vyaparapp.in' :
			'https://staging.vyaparapp.in'; // accessing api from https
		const res = await axios.get(`${AUTH_API_BASE_URL}/api/ns/users`, {
			headers: {
				Authorization: `Bearer ${APIService.getAuthToken()}`,
			}
		});
		// console.log(res.data);
		const { id, name, email, phone, roles } = res.data;
		let singleRole;
		for(let r of roles||[]) {
			if (r===Roles.ADMIN) { singleRole = r; break; } 
			else if (r===Roles.AGENT) { singleRole = r; }
		}
		// singleRole = Roles.ADMIN;
		if (!singleRole) {
			throw new Error("Unauthorized.");
		}
		// const data = {
		// 	id: 1, name: 'Agent1', email, phone, role: singleRole
		// };
		const data = { id, name, email, phone, role: singleRole };
		// console.log(data);
		return data;
	} catch(e) {
		console.error(e);
		return {};
	}

	// await delay(1);
	// return {
	// 	id: 1,
	// 	name: "Agent1",
	// 	email: "agent1@vyaparapp.in",
	// 	phone: '7667766788',
	//  	role: "cs_wa_admin",
	// }
}

APIService.getCategoriesSubcategories = async function (category = null) {
	// gets all categories => [sub-categories]
	try {
		const res = await axios.get(`${API_BASE_URL}/categories`, {
			headers: {
				Authorization: `Bearer ${APIService.getAuthToken()}`
			}
		});
		// console.log(res.data);
		return res.data;
	} catch(e) {
		console.error(e);
	  	return;
	}

	// await delay(1);
	// return [{
	// 	category: 'CategoryA',
	// 	sub_categories: ['SubCategoryA1', 'SubCategoryA2'],
	// }, {
	// 	category: 'CategoryB',
	// 	sub_categories: ['SubCategoryB1', 'SubCategoryB2', 'SubCategoryB3', 'SubCategoryB4'],
	// }, {
	// 	category: 'CategoryC',
	// 	sub_categories: ['SubCategoryC1', 'SubCategoryC2', 'SubCategoryC3'],
	// }, {
	// 	category: 'CategoryD',
	// 	sub_categories: ['SubCategoryD1', 'SubCategoryD2'],
	// }, {
	// 	category: 'CategoryE CategoryE CategoryE',
	// 	sub_categories: ['SubCategoryE1 SubCategoryE1 SubCategoryE1', 'SubCategoryE2'],
	// }]
}

APIService.getAgents = async function (params={}) {
	// gets all agents - cs_wa_agent
	try {
		const res = await axios.get(`${API_BASE_URL}/agents`, {
			headers: {
				Authorization: `Bearer ${APIService.getAuthToken()}`
			},
			params,
		});
		// console.log(res.data);
		return res.data;
	} catch(e) {
		console.error(e);
	  	return;
	}

	// await delay(1);
	// // return [
	// // 		{ id:1, name: 'Agent1' },
	// // 		{ id:2, name: 'Agent2' },
	// // 		{ id:3, name: 'Agent3' },
	// // 		{ id:4, name: 'Agent4' }
	// // ]
	// if (params.limit>=0 &&  params.skip>=0) {
	// 	return agentAttendanceData.slice(params.skip, params.skip+params.limit);
	// }
	// return agentAttendanceData;
}

APIService.getChatList = async function ({
	// for AllChats pagination is used and for MyChats all chats are fetched
	skip=0, agentId, category, subCategory, status, startDate, endDate 
} = {}) {
	try {
		let limit = 20;
		const params = {};
		if (agentId) { // if agentId defined then get all chats assigned. (Assuming it to be less for one agent)
			params.agent_id = [agentId]+'';
			limit = null;
			skip = null;
		}
		if (category && category.length>0) params.category = category+'';
		if (subCategory && subCategory.length>0) params.sub_category = subCategory+'';
		if (status && status.length>0) params.status = status+'';
		if (startDate) {
			const dateParts = startDate.split('-');
			// Formatting as MM/DD/YYYY to make it IST instead of UTC
			params.start_date = new Date(`${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`)
				.toISOString();
		}
		if (endDate) {
			const dateParts = endDate.split('-');
			// Formatting as MM/DD/YYYY to make it IST instead of UTC
			params.end_date = new Date(`${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`)
				.toISOString();
		}
		// console.log(params);
		const res = await axios.get(`${API_BASE_URL}/chat-list`, {
			headers: {
				Authorization: `Bearer ${APIService.getAuthToken()}`
			},
			params: { skip, limit, ...params }
		});
		// console.log(res.data);
		return res.data;
	} catch(e) {
		toast.error('Error while getting Chats. Please try again!');
		console.error(e);
	  	return;
	}

	// await delay(1);
	// // if agentId passed then get chats assigned to that agent else fetch all chats
	// let filteredChats = [...chatListItems];
	// if (agentId) {
	// 	filteredChats = filteredChats.filter(i => i.agent.id === agentId);
	// }
	// if (status && status.length) {
	// 	filteredChats = filteredChats.filter(i => status.includes(i.status));
	// }
	// if (category && category.length) {
	// 	filteredChats = filteredChats.filter(i => category.includes(i.category));
	// }
	// if (subCategory && subCategory.length) {
	// 	filteredChats = filteredChats.filter(i => subCategory.includes(i.sub_category));
	// }
	// const limit = 20;
	// const [start, end] = [skip, skip+limit];
	// const chatList = filteredChats.slice(start, end);
	// return chatList;
}

APIService.getChatMessages = async function ({ wa_number, skip=0 }) {
	try {
		const limit = 20;
		const res = await axios.get(`${API_BASE_URL}/chat-messages`, {
			headers: {
				Authorization: `Bearer ${APIService.getAuthToken()}`
			},
			params: {
				wa_number, skip, limit,
			}
		});
		// console.log(res.data);
		return res.data;
	} catch(e) {
		toast.error('Error while getting Chat Messages. Please try again!');
		console.error(e);
	  	return;
	}

	// console.log(`getChatMessages called ${skip}`);
	// await delay(1);
	// const limit = 20;
	// const [start, end] = [skip, skip+limit];
	// const retMessages = messages.slice(start, end).reverse();
	// // get messages for a whatsapp number
	// return retMessages;
}

APIService.saveIncidentInfo = async function ({
	wa_number, status, category, sub_category, agent
}) {
	try {
		const res = await axios.post(`${API_BASE_URL}/incident-info`, {
			wa_number, status, category, sub_category, agent
		}, {
			headers: {
				Authorization: `Bearer ${APIService.getAuthToken()}`
			},
		});
		// console.log(res.data);
		return res.data;
	} catch(e) {
		toast.error('Error while saving Info. Please try again!');
		console.error(e);
	  	return;
	}

	// await delay(1);
	// return {
	// 	statusCode: 200,
	// 	message: "update successful"
	// };
}

APIService.sendOutMessage = async function ({ wa_number, type, sub_type, content }) {
	try {
		if (type==='text' && content.length > 3000) {
			// 3825 is exact even though 4096 is mentioned in vfirst docs
			throw new Error(`Text message cannot be more than 3000 characters. Yours is ${content.length} characters long.`);
		}
		const res = await axios.post(`${API_BASE_URL}/send-out-message`, {
			wa_number, type, sub_type, content,
		}, {
			headers: {
				Authorization: `Bearer ${APIService.getAuthToken()}`
			},
		});
		// console.log(res.data);
		return res.data;
	} catch(e) {
		const errMsg = e.response?.data?.message ? e.response.data.message :
			e.message ? e.message: `Error while sending message. Please try again!`;
	  	toast.error(errMsg);
		console.error(e);
		return;
	}

	// await delay(1);
	// return { message: "Message sent successfully." };
}

APIService.saveAgentAttendance = async function ({ id, absent }) {
	try {
		const res = await axios.post(`${API_BASE_URL}/attendance`, {
			id, absent
		}, {
			headers: {
				Authorization: `Bearer ${APIService.getAuthToken()}`
			},
		});
		// console.log(res.data);
		return res.data;
	} catch(e) {
		toast.error('Attendance update failed. Please try again!');
		console.error(e);
	  	return;
	}

	// await delay(1);
	// return { message: 'attendance updated successfully' };
}

APIService.getAutoresponseSettings = async function () {
	// to get autoreponse setting
	try {
		const res = await axios.get(`${API_BASE_URL}/autoresponse-settings`, {
			headers: {
				Authorization: `Bearer ${APIService.getAuthToken()}`
			},
		});
		// console.log(res.data);
		return res.data;
	} catch(e) {
		toast.error("Error while getting Autoresponse Settings. Please try again!");
		console.error(e);
	  	return;
	}

	// await delay(1);
	// return autoresponse;
}

APIService.saveAutoresponseSettings = async function ({ settings }) {
	// checking each message length & removing _id
	let customErrMsg = false;
	try {
		settings = settings.map(({ _id, ...onlySetting }) => {
			if (onlySetting.response_text.length > 1000) {
				customErrMsg = true;
				throw new Error(`Response text cannot be more then 1000 characters. Yours is ${onlySetting.response_text.length} characters long.`);
			}
			return onlySetting
		});

		// to update autoreponse setting
		const res = await axios.post(`${API_BASE_URL}/autoresponse-settings`, {
			settings
		}, {
			headers: {
				Authorization: `Bearer ${APIService.getAuthToken()}`
			},
		});
		// console.log(res.data);
		return res.data;
	} catch(e) {
		let errMsg = `Autoresponse Settings update failed. Please try again!`;
		if (customErrMsg) errMsg = e.message;
		toast.error(errMsg);
		console.error(e);
	  	return;
	}

	// await delay(1);
	// return { message: "Autoresponse settings saved successfully." };
}

APIService.syncAgents = async function () {
	// syncs agents from mysql to mongodb. UI needs to be reloaded
	try {
		const res = await axios.get(`${API_BASE_URL}/sync-agents`, {
			headers: {
				Authorization: `Bearer ${APIService.getAuthToken()}`
			},
		});
		// console.log(res.data);
		return res.data;
	} catch(e) {
		console.error(e);
		toast.error('Error while syncing agents. Please try again!');
	  	return;
	}
}

// function delay(t) {
// 	return new Promise(resolve => {
// 		setTimeout(() => {
// 			resolve();
// 		}, t * 1000);
// 	})
// }

export default APIService;