import './App.css';
import { useState, useEffect, useContext, useCallback } from 'react';
import ChatListPanel from './components/ChatListPanel';
import DisplayPanel from './components/DisplayPanel';
import { AppContext } from './contexts/AppContext';
import APIService from './services/APIService';
import Loading from './components/common/Loading';
import { Actions } from './enums/Actions';
import { w3cwebsocket as W3CWebsocket } from 'websocket';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from './components/common/Spinner';
import 'react-toastify/dist/ReactToastify.css';
import Unauthorized from './components/common/Unauthorized';

function App() {
  const [authorized, setAuthorized] = useState(null);
  const { dispatch, state } = useContext(AppContext);
  const { showSpinner } = state;
  const authorizeCurrentAgent = useCallback(async () => {
    const [agent, categories, agents] = await Promise.all([
      APIService.authorizeAgent(), // authorize user
      APIService.getCategoriesSubcategories(), // get all categories/subcategories data
      APIService.getAgents() // get all agents
    ]);
    if (agent?.id && categories && agents) {
      dispatch({ 
        type: Actions.SET_INITIAL_STATE, 
        payload: { agent, categories, agents }
      })
      setAuthorized(true);
    } else {
      setAuthorized(false);
    }
  }, [dispatch]);

  useEffect(() => {
    authorizeCurrentAgent();
  }, [authorizeCurrentAgent])

  useEffect(() => {
    // connection is created only if user is authorized
    if (authorized) {
      const timer = 500;
      const connect = () => {
        // AWS websocket has a timeout of 10 minutes for idle connections. So a reconnect is attempted as soon as it happens
        // This cannot be changed as AWS has kept it to provide scalability.
        console.log("websocket connecting.");
        const ws = new W3CWebsocket(
          `${process.env.REACT_APP_WEBSOCKET_URL}/${APIService.STAGE}?auth_token=${APIService.getAuthToken()}`
        );
        // websocket onopen event listener
        ws.onopen = () => {
          toast.success("Connected.");
          console.log("websocket connected.");
        };
        // websocket onclose event listener
        ws.onclose = (e) => {
          toast.warn(`Disconnected${e.reason? ` (${e.reason})`: ''}. Reconnect will be attempted in ${timer/1000} seconds.`);
          console.log(
            `websocket closed${e.reason? ` (${e.reason})`: ''}. Reconnect will be attempted in ${timer/1000} seconds.`,
          );
          setTimeout(() => checkSocket(ws), timer); //call check function after timeout
        };
        // websocket onerror event listener
        ws.onerror = (err) => {
          toast.error("Error while connecting. Try reloading!");
          console.error(`websocket error${err.message? `: ${err.message}`: ''}. Closing socket.`);
          ws.close();
        };
        // websocket onmessage event listener
        ws.onmessage = (e) => {
          if (typeof e.data === 'string') {
            const { action, data } = JSON.parse(e.data);
            const newMessageEvent = new CustomEvent(action, {
              bubbles: false,
              cancelable: true,
              detail: { action, data }, // actions can be - info, message
            });
            document.dispatchEvent(newMessageEvent);
          }
        };
      }
      const checkSocket = (ws) => {
        //check if websocket instance is closed, if so call `connect` function.
        if (!ws || ws.readyState===WebSocket.CLOSED) connect();
      };

      connect();
    }
  }, [authorized])

  const display = authorized===null ? <Loading/> : <Unauthorized/>;

  return (
    <div className='row'>
      {
        authorized ?
        <>
          <div className='col-3 border' style={{height:'100vh', overflow:'hidden'}}>
            <ChatListPanel/>
          </div>
          <div className='col-9 displayPanelBg' style={{height:'100vh', overflow:'hidden'}}>
            <DisplayPanel/>
          </div>
          <ToastContainer/> {/* common toastr at the top level */}
          { showSpinner && <Spinner/> } {/* common spinner at the top level */}
        </> :
        <>
          <div className='col' style={{ textAlign: "center", height: "100vh" }}>
            { display }
          </div>
        </>
      }
    </div>
  );
}

export default App;
