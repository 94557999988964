
import React, { useState, useEffect, useContext, useRef } from 'react';
import './SendMessageEditor.css';
import APIService from "../services/APIService";
import { toast } from 'react-toastify';
import { Actions } from '../enums/Actions';
import { AppContext } from '../contexts/AppContext'


function SendMessageEditor({ data }) {
	const [text, setText] = useState('');
	const { wa_number, disabled } = data;
	const { dispatch } = useContext(AppContext);
	const uploadInputRef = useRef();
	const [showPreview, setShowPreview] = useState(false);
	let uploadFile = useRef(null); // for base64 uploaded file data

	useEffect(() => {
		// height of the input area is handled dynamically. 
		// it expands when multiple lines are typed.
		const autoExpand = (field) => {
			// Reset field height
			field.style.height = 'inherit';
			// Get the computed styles for the element
			var computed = window.getComputedStyle(field);
			// Calculate the height
			var height = parseInt(computed.getPropertyValue('border-top-width'), 5)
				+ parseInt(computed.getPropertyValue('padding-top'), 5)
				+ field.scrollHeight
				+ parseInt(computed.getPropertyValue('padding-bottom'), 5)
				+ parseInt(computed.getPropertyValue('border-bottom-width'), 5);
			field.style.height = `${ height }px`;
		};
		const element = document.getElementById('sendMessageField');
		// height is updated based on the input typed
		element.addEventListener('keyup', function (event) {
			autoExpand(event.target);
		}, false);
	}, [])
	
	// called when send button is pressed
	const sendOutMessage = async () => {
		// only if not disabled and either text is added or media is selected
		if (!disabled && (text.trim().length || uploadFile.current)) {
			dispatch({ type: Actions.SET_SPINNER_STATUS, payload: true });
			let [type, sub_type, content] = ['text', null, text]; // default is text

			// if media file has been selected then uploadFile is set.
			// then update the content and type/sub_type
			if (uploadFile.current) {
				sub_type = uploadFile.current.type;
				content = uploadFile.current.content;
				type = uploadFile.current.type.split('/')[0];
				if (type==='application') type = 'document';
			}
			const response = await APIService.sendOutMessage({
				wa_number, type, sub_type, content,
			});
			dispatch({ type: Actions.SET_SPINNER_STATUS, payload: false });
			if (response) {
				setText(''); // resetting input field
				uploadFile.current = null; // making uploaded value null after send opearation is complete
				setShowPreview(false);
				// to resize text input height
				const element = document.getElementById('sendMessageField');
				element.style.height = 'inherit'; // resetting height
				toast.success(response.message);
			}
			// focus on text area after sending message
			const inputArea = document.getElementById('sendMessageField');
			if (inputArea) inputArea.focus(); // focussing back on input field after sending
		}
	}

	// shortcuts to send text message - ctrl+enter OR shift+enter
	const handleKeyPress = (e) => {
		if ((e.ctrlKey || e.shiftKey) && e.keyCode === 13) {
			e.preventDefault();
			//send message if ctrl+enter or shift+enter
			sendOutMessage();
		}
	}

	// called when an attachment is selected
	// hides input text field when input file is selected
	const handleChangeFileInput = (event) => {
		const input = event.target;

		if (input.files && input.files[0]) {
			const file = input.files[0]
			setShowPreview(true);
			const reader = new FileReader();
			reader.onload = function(e) {
				// file is converted to base64 by FileReader browser API
				uploadFile.current = {
					type: file.type,
					content: e.target.result
				};
				const superType = file.type.split('/')[0];
				let src;
				// if not image then a default preview image is shown in UI
				if (superType==='image') {
					src = e.target.result;
				} else if (superType==='video') {
					src = 'images/video-icon.jpg';
				} else if (superType==='audio') {
					src = 'images/audio-icon.png';
				} else {
					src = 'images/doc-icon.jpg';
				}
				document.getElementById('uploaded-preview').src = src;
			};
			// actually starts converting to base64 string
			// reader.onload is called in async manner when this is invoked
			reader.readAsDataURL(input.files[0]);
		}
	}

	// called when cross is clicked on input file preview.
	// shows input field again
	const handleCancelUpload = (event) => {
		event.preventDefault();
		setShowPreview(false);
		document.getElementById('uploaded-preview').src = '#';
		uploadFile.current = null;
		if (uploadInputRef) uploadInputRef.current.value = '';
	}

	return (
		<div className='SendMessageEditor'>
			<div className='col-12 my-2 '>
				<div className='row d-flex align-items-center'>
					<div className='col-1 text-end'>
						<img src='images/attachfile.svg' 
							onClick={() => {!disabled && uploadInputRef && uploadInputRef.current.click()}} 
							style={{ cursor: `${ disabled ? 'no-drop' : 'pointer' }`}} 
							alt='attach file icon'
						/>
						{/* Replace following with one above once attachments are implemented */}
						{/* <img src='images/attachfile.svg' style={{ cursor: 'no-drop'}} alt='attach file icon' /> */}
						<input ref={uploadInputRef} accept='image/*,video/*,audio/*,application/pdf' type='file' style={{display: 'none'}} onChange={handleChangeFileInput}/>
					</div>
					<div className='col-10 d-flex'>
						{/* When file is uploaded then text cannot be sent with it */}
						{ showPreview && 
						  <div>
							<img id='uploaded-preview' src='#' alt='uploaded file' style={{width: 80, height: 90}}/>
							<a className='upload-cancel' onClick={handleCancelUpload}></a>
						  </div>
						}
						<textarea hidden={showPreview} id='sendMessageField' disabled={ disabled } 
							value={ text } 
							onChange={ (e) => setText(e.target.value) }
							onKeyDown={ handleKeyPress }
    						tabIndex='0'
							className='sendMessageInputBox w-100 pt-3 px-4 border' 
							placeholder={ !disabled ? 'Type a message' : `Chat is disabled as either it's RESOLVED or not assinged to you!`}
							style={{ cursor: `${ disabled ? 'no-drop' : 'auto' }` }}>
						</textarea>
					</div>
					<div className='col-1'>
						<img src='images/send.svg' 
							style={{ cursor: `${ disabled ? 'no-drop' : 'pointer' }`}} 
							alt='text send icon'
							onClick={ sendOutMessage }
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SendMessageEditor;