import React, { createContext, useReducer } from 'react';
import { AppReducer, initialState } from './AppReducer'

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  // use Reducer with initial state here!
  const [state, dispatch] = useReducer(AppReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      { children }
    </AppContext.Provider>
  );
}