
import React, { useState, useContext, useEffect } from 'react';
import AgentAbsenceMarker from './AgentAbsenceMarker';
import { AppContext } from '../../contexts/AppContext';
import APIService from '../../services/APIService';
import { toast } from 'react-toastify';
import Loading from '../common/Loading';
import DateService from '../../services/DateService';
import { Actions } from '../../enums/Actions';

export default function AgentAbsence() {
	const { dispatch } = useContext(AppContext);
	const [attendanceData, setAttendanceData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [noAgents, setNoAgents] = useState(false);
	const [loading, setLoading] = useState(false);
	// to increase the number of rows, simple increase the number here. 
	// Rows cannot be reduced like this though.
	const pageSize = 7;

	// to go to previous page list
	const prevPage = () => {
		if (currentPage === 1) {
			setCurrentPage(currentPage);
		} else {
			setCurrentPage(currentPage-1);
			setNoAgents(false);
		}
	};
	// to go to next page list
	const nextPage = () => {
		if (!noAgents) {
			setCurrentPage(currentPage+1);
		} else {
			setCurrentPage(currentPage);
		}
	};

	// called as the component renders. Fetched first page of agents list
	useEffect(() => {
		const getAgentsAttendance = async () => {
			if (!noAgents) {
				setLoading(true);
				const response = await APIService.getAgents({ 
					all_info: 1, // required to get absent field with agents list
					skip: (currentPage-1) * pageSize, 
					limit: pageSize
				});
				if (response) {
					if (response.length) {
						setAttendanceData(response);
					} else {
						setNoAgents(true);
						setCurrentPage(currentPage-1);
						toast.warning('No more agents available!');
					}
				} else {
					toast.error('Error while getting Attendance info. Please try again!');
				}
				setLoading(false);
			}
		}
		getAgentsAttendance();
	}, [currentPage, noAgents])

	// called when attendance checkbox is clicked for any agent
	const updateAgentsAttendance = async (data) => {
		dispatch({ type: Actions.SET_SPINNER_STATUS, payload: true });
		const response = await APIService.saveAgentAttendance({
			id: data.id, absent: data.absent
		});
		dispatch({ type: Actions.SET_SPINNER_STATUS, payload: false });
		if (response) {
			const updatedAttendance = attendanceData.map(
				agent => agent.id === data.id ? { ...data } : agent
			);
			setAttendanceData(updatedAttendance);
			toast.success('Attendance updated successfully.');
		}
	}
	
    return (
		<>
			<div className='d-flex justify-content-between align-items-center px-4 AgentAbsenceMainDiv'>
				<div className='AgentAbsenceNameDateDiv' >
					Agent Name
				</div>
				<div className='AgentAbsenceNameDateDiv'>
					<i className="far fa-calendar-alt me-2"></i>
					<span>
						{ DateService.dateStr(new Date()) }
					</span>
				</div>
				<div className='AgentAbsenceNameDateDiv'>
					<i onClick={prevPage} style={ currentPage === 1 ? { cursor: 'no-drop' } : { cursor: 'pointer' } }
						className="fas fa-arrow-left text-white me-2">
					</i>
					<i onClick={nextPage} 
						style={ noAgents ? { cursor: 'no-drop' } : { cursor: 'pointer' } }
						className="fas fa-arrow-right text-white ms-2 ">
					</i>
				</div>
			</div>
			<div className=' d-flex flex-column py-2 px-4 align-items-center AgentAbsenceBodyDiv'>
			{
				loading ? <Loading/> : 
				attendanceData && attendanceData.map(
					agent => <AgentAbsenceMarker data={{ agent, updateAgentsAttendance }} key={agent.id}/>
				)
			}
			</div>
		</>
    )
}
