
import React, { useState, useEffect, useMemo } from 'react';
import './AutoresponseRow.css';
import AutoresponseRowDropdown from './AutoresponseRowDropdown';
import VerticalPipeLine from './VerticalPipeLine';
import { toast } from 'react-toastify';

export default function AutoresponseRow(props) {
    const { autoRow, handleChangeTempState, setShowButtons } = props.data;
    const DAYS = useMemo(() => {
        return {
            'Weekday': 0, 'Monday': 1, 'Tuesday': 2, 'Wednesday': 3,
            'Thursday': 4, 'Friday': 5, 'Saturday': 6, 'Sunday': 7
        };
    }, []);
    const days = Object.keys(DAYS);

    // setting default row data
    const [rowStatus, setRowStatus] = useState({
        id: '',
        startDay: {
            day: 'Weekday',
            dayIndex: DAYS['Weekday']
        },
        endDay: {
            day: 'Weekday',
            dayIndex: DAYS['Weekday']
        },
        startTime: '00:00',
        endTime: '23:59',
        responseText: '',
        sendResponse: false,
        closeChat: false,
        priority: ''
    });

    // setting initial row data as received from parent component
    useEffect(() => {
        const settingData =  {
            id: autoRow._id,
            startDay: {
                day: autoRow.start_day,
                dayIndex: DAYS[autoRow.start_day]
            },
            endDay: {
                day: autoRow.end_day,
                dayIndex: DAYS[autoRow.end_day]
            },
            startTime: autoRow.start_time,
            endTime: autoRow.end_time,
            responseText: autoRow.response_text,
            sendResponse: autoRow.send_response,
            closeChat: autoRow.close_chat,
            priority: autoRow.priority
        }
        setRowStatus(settingData);
    }, [autoRow, DAYS])

    useEffect(() => {
        // helper func to compare hours and mins
        const compareTime = (start, end) => {
            let [startHour, startMin] = start.split(':');
            let [endHour, endMin] = end.split(':');
            [startHour, startMin, endHour, endMin] = [+startHour, +startMin, +endHour, +endMin];
            if (startHour > endHour || (startHour === endHour && (startMin > endMin || startMin === endMin))) {
                return true;
            }
            return false;
        }
        
        // to compare start day with end day
        if (rowStatus.startDay.day !== 'Weekday' && rowStatus.endDay.day !== 'Weekday' ) {
            if (rowStatus.startDay.dayIndex > rowStatus.endDay.dayIndex) {
                toast.error('Start day must come before end day!');
                setRowStatus({
                    ...rowStatus,
                    endDay: {
                        day: 'Weekday',
                        dayIndex: 0
                    }
                })
            }
        }

        // to compare start time with end time
        if (rowStatus.startTime && rowStatus.endTime) {
            const invalidTime =  compareTime(rowStatus.startTime, rowStatus.endTime);
            if (invalidTime) {
                toast.error('Start time must be less than end time!');
                setRowStatus({
                    ...rowStatus,
                    endTime: '23:59'
                })
            }
        }
    }, [rowStatus])

    // to handle send response checked
    const handleTimeChange = (data) => {
        setRowStatus({
            ...rowStatus,
            ...data,
        });
        handleChangeTempState({
            ...rowStatus,
            ...data,
        });
    }
    
    // to handle send response checked
    const handleSendResponse = (e) => {
        let obj;

        // updating days validity
        if ((rowStatus.startDay.day === 'Weekday' || rowStatus.endDay.day === 'Weekday') || (rowStatus.startTime === '' || rowStatus.endTime === '')) {
            toast.error('Please select day and time!');
            obj = { ...rowStatus, sendResponse: false };
            setRowStatus(obj); // updating temp state
        } else if (rowStatus.responseText.trim().length === 0) { // setting response message lenght
            toast.error('Please enter a valid response message!');
            obj = { ...rowStatus, response_text: rowStatus.responseText.trim(), sendResponse: false };
            setRowStatus(obj); // updating temp state
        } else if (!e.target.checked && rowStatus.closeChat) { // checking if close chat checkbox selected
            obj = { ...rowStatus, sendResponse: e.target.checked, closeChat: e.target.checked };
            setRowStatus(obj); // updating temp state
            setShowButtons(true); // show save/cancel buttons
        } else {
            obj = { ...rowStatus, sendResponse: e.target.checked };
            setRowStatus(obj); // updating temp state
            setShowButtons(true); // show save/cancel buttons
        }
        handleChangeTempState(obj);
    }
    // to handle close chat checkbox change
    const handleChatClose = (e) => {
        if (!rowStatus.sendResponse) {
            toast.error('Send response should be checked!');
        } else {
            let obj = { ...rowStatus, closeChat: e.target.checked };
            setRowStatus(obj);
            handleChangeTempState(obj);
            setShowButtons(true);
        }
    }
    // to handle text message changes
    const handleTextChange = (e) => {
        const value = e.target.value;
        let obj = {
            ...rowStatus,
            responseText: value
        }
        if (value.trim().length === 0) {
            const previousSendResponse = obj.sendResponse;
            obj = { ...obj, 
                sendResponse: false,
                closeChat: false,
            }
            setRowStatus(obj);
            if (previousSendResponse) setShowButtons(true);
        } else {
            setRowStatus(obj);
        }
        handleChangeTempState(obj);
    }

    return (
        <>
            <tr className={ `${rowStatus.priority%2 === 1 ? 'bg-light' : ''} AutoresponseRow` }>
                <td className='text-center'><span className='AutoresponseRowIndex'>{ rowStatus.priority }</span></td>
                <td><VerticalPipeLine/></td>

                <td className='text-center'>
                    <div>
                    { <AutoresponseRowDropdown data={{ tagName: 'startDay', days, rowStatus,  setRowStatus, handleChangeTempState }} /> }
                        <span className='mx-1'>to</span>
                    { <AutoresponseRowDropdown data={{ tagName:'endDay', days, rowStatus, setRowStatus, handleChangeTempState }} /> }
                        
                    </div>
                </td>
                <td><VerticalPipeLine/></td>

               <td className='text-center'>
                    <input type='time' value={ rowStatus.startTime }
                        className={ `${rowStatus.priority%2  === 1 ? 'AutoresponseRowTimeInputOdd' : 'AutoresponseRowTimeInputEven'} rounded-pill bg-white` }
                        onChange={e => { handleTimeChange({ startTime: e.target.value }) }}
                    />
                    <span className='mx-2'>to</span>
                    <input type='time' value={ rowStatus.endTime }
                    className={ `${rowStatus.priority%2  === 1 ? 'AutoresponseRowTimeInputOdd' : 'AutoresponseRowTimeInputEven'} rounded-pill bg-white` }
                     onChange={e => { handleTimeChange({ endTime: e.target.value }) }}
                    />
                </td>
                <td><VerticalPipeLine/></td>
                 
                <td className='text-center'>
                    <textarea cols='28' rows='2' value={ rowStatus.responseText } 
                        placeholder='please write message here..'
                        onChange={ (e) => handleTextChange(e)}
                        className={ `${rowStatus.priority%2  === 1 ? 'AutoresponseRowTextareaOdd' : 'AutoresponseRowTextareaEven'} rounded p-2` } 
                        >
                    </textarea>
                </td>
                <td><VerticalPipeLine/></td>
                
                <td className='text-center'>
                    <input 
                        checked = { rowStatus.sendResponse }
                        onChange={ (e) => handleSendResponse(e)}
                        className="form-check-input mt-0 me-2 AutoresponseRowCheckbox"
                        type="checkbox"  id={ `sendCheckbox-${autoRow._id}` }/>
                    <label className="form-check-label sendResponseText" htmlFor={ `sendCheckbox-${autoRow._id}` } >Send Response</label>
                </td>
                <td><VerticalPipeLine/></td>

                <td className='text-center'>
                    <input
                        checked = { rowStatus.closeChat }
                        onChange={ (e) => handleChatClose(e)}
                        className="form-check-input mt-0 me-2 AutoresponseRowCheckbox" 
                        type="checkbox"  id={ `closeCheckbox-${autoRow._id}` } />
                        <label className="form-check-label me-1 closeChatText" htmlFor={ `closeCheckbox-${autoRow._id}` }>Close Chat</label>
                </td>
            </tr>
        </>
    )
}
