import './App.css';
import { AppProvider } from './contexts/AppContext';
import Init from './Init';

function App() {
  return (
    <AppProvider> {/* Encapsulating whole app withing the global context to share state */}
      <Init/>
    </AppProvider>
  );
}

export default App;
