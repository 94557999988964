
import AgentAbsenceContainer from './AgentAbsenceContainer';
import SimpleHeader from '../common/SimpleHeader';
import './AgentAttendance.css';

function AgentAttendance() {
  return (
    <>
      <div className='AgentAttendance bg-white'>
        <SimpleHeader heading="Agent Attendance"/>
        <AgentAbsenceContainer/>
      </div>
    </>
  );
}

export default AgentAttendance;