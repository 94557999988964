/* eslint-disable jsx-a11y/anchor-is-valid */
import './SettingsMenuBar.css';
import { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Pages } from  '../enums/Pages';
import { Actions } from '../enums/Actions';
import { Roles } from '../enums/Roles';
import APIService from '../services/APIService';
import { toast } from 'react-toastify';

function SettingsMenuBar() {
	const { state, dispatch } = useContext(AppContext);
	const { agent } = state;

	// called when sync agents button is clicked. Reload is required to see the changes.
	const syncAgents = async () => {
		dispatch({ type: Actions.SET_SPINNER_STATUS, payload: true });
		const response = await APIService.syncAgents();
		dispatch({ type: Actions.SET_SPINNER_STATUS, payload: false });
		if (response) {
			toast.success('Agents synced successfully. Please reload.');
		}
	}

	// called when settings menu is clicked
	const updateCurrentPage = (currentPage) => {
		dispatch({ type: Actions.SET_CURRENT_PAGE, payload: currentPage })
	}

  	return (
      	<div className="d-flex flex-row justify-content-end settingsMenu">
			{	
				// <div className="d-flex flex-row-reverse settingsMenu"> - earlier this was used
				// this is placed here temporarily. Should be moved to a better place. Also &nbsp; below
				agent?.role===Roles.ADMIN &&
				<button className='settingsButton px-3 py-2 me-1' onClick={syncAgents} type="button">Sync Agents</button>
			}
			&nbsp;&nbsp;
			<button className=" dropdown-toggle settingsButton px-3 py-2 me-1" type="button" data-bs-toggle="dropdown">Settings  </button>
			<ul className="dropdown-menu rounded">
				<li className='dropdown-item py-2' onClick={ () => updateCurrentPage(Pages.AGENT_HOME) }>My Dashboard</li>
				{agent?.role===Roles.ADMIN &&
					<li className="dropdown-item py-2"  onClick={ () => updateCurrentPage(Pages.AUTORESPONSE_SETTINGS) }>Autoresponse</li>
				}
				{agent?.role===Roles.ADMIN &&
					<li className="dropdown-item py-2"  onClick={ () => updateCurrentPage(Pages.ATTENDANCE) }>Agent Attendance</li>
				}
			</ul>
		</div>
  );
}

export default SettingsMenuBar;