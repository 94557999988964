import './ChatListItem.css';
import React, { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Actions } from '../enums/Actions';
import { ChatStatus } from '../enums/ChatStatus';
import { AutoresponseAgent } from '../enums/Roles';

function ChatListItem(props) {
  const { state } = useContext(AppContext);
  const { currentPage } = state;
  const { 
    wa_number, status, category, sub_category, agent, last_msg
  } = props.data;
  
  const { dispatch } = useContext(AppContext);

  // function to set color for status based on status value
  const getStatusColor = (status) => {
    switch (status) {
      case ChatStatus.RESOLVED:
        return 'text-success';
      case ChatStatus.ACTIVE:
        return 'text-primary';
      case ChatStatus.IDLE:
        return 'text-danger';
      case ChatStatus.NEW:
        return 'text-warning';
      default:
        return 'text-warning';
    }
  }

  // called when a ChatListItem is clicked.
  const setCurrentChat = () => {
    dispatch({
      type: Actions.SET_CURRENT_CHAT, 
      payload: {
        wa_number, category, sub_category, status, agent
      }
    });
  }

  return (
    <>
      <div className={ `${currentPage===wa_number ? 'activeChatListItem ': ''} chatlistItem col-12 border-bottom` }
        id={`chatListItem-${wa_number}`} onClick={setCurrentChat}>
        <div className='row'>
          <div className='col-8'>
            <span className="chatWhatsappNumber">{ wa_number }</span>
          </div>
          <div className='col-4 text-end'>
            <span className={ `${getStatusColor(status)} chatCurrentStatus` }>{ status }</span>
          </div>
        </div>
        <div>
            <span className='chatLastSender'>{ last_msg.direction==='IN'? 'User': last_msg.agent?.name||AutoresponseAgent } :</span>
        </div>
        <div className='chatLastText'>
          <span className=' mt-2'>{ last_msg.content }</span>
        </div>
      </div>
	  </>
  );
}

export default ChatListItem;
