
import React from 'react';
import './Common.css';

function ShowInfoOver({ content }) {
    return (
        <div className='text-secondary col-3 text-center py-2 bg-light showInfoOver'>
            { content }
        </div>
    )
}

export default ShowInfoOver;
