
import React from 'react';

function ClearFilterSubMenu(props) {
	return (
		<>
		<li  className="dropdown-item text-center text-primary float-none" onClick={ props.onClick }>clear</li>
		</>
	)
}

export default ClearFilterSubMenu;
