
import { Pages } from '../enums/Pages';
import { Actions } from '../enums/Actions';

export const initialState = {
    agent: null, // logged in agent
    currentPage: Pages.AGENT_HOME, // currently visible page in DisplayPanel - Autoresponse, Attendance, AgentHome
    currentChat: {
      wa_number: null,
			category: null,
			sub_category: null,
			status: null,
			agent: null
    },
    chatList: [], // chat list visible in the ChatListPanel
    categories: [], // all category & subcategory info
    agents: [], // all agents
    showSpinner: false, // to show spinner on request send
}

export const AppReducer = (state = initialState, { type, payload }) => {

    switch(type) {
      
      case Actions.SET_INITIAL_STATE: {
        return {
            ...state,
            agent: payload.agent,
            categories: payload.categories,
            agents: payload.agents
        };
      }
      case Actions.SET_CURRENT_CHAT: {
        return{
            ...state,
            currentPage: payload.wa_number,
            currentChat: payload
        };
      }
      case Actions.SET_CURRENT_PAGE: {
        return{
            ...state,
            currentPage: payload,
            currentChat: initialState.currentChat
        };
      }
      case Actions.SET_CHAT_LIST: {
        return {
            ...state,
            chatList: payload
        };
      }
      case Actions.UPDATE_CHAT_INFO: {
        const updatedChatList = state.chatList.map(l => {
          if(l.wa_number === payload.wa_number) {
            return { ...l, ...payload };
          }
          return l;
        });
        return {
            ...state,
            currentChat: payload,
            chatList: updatedChatList
        };
      }
      case Actions.UPDATE_SOCKET_MESSAGE_AND_STATUS: {
        return {
          ...state,
          chatList: payload.chatList? payload.chatList: state.chatList,
          currentChat: payload.currentChat? payload.currentChat: state.currentChat,
        };
      }
      case Actions.SET_SPINNER_STATUS: {
        return {
          ...state,
          showSpinner: payload
        }
      }
      default: {
        return state;
      }
    }
  }