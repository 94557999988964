/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { DropdownIds } from '../enums/DropdownDefaults';
import './DropdownMenu.css';

function DropdownMenu(props) {
  const { id, options, value, onChange, disabled } = props.data;
  const [currentVal, setCurrentValue]  = useState(value);
  const [currentOptions, setCurrentOptions] = useState(options);

  // handles change in dropdown
  const handleChange = ({ optKey, optValue }) => {
    if (onChange) {
      let value = optValue;
      // if ASSIGNEE dropdown then values is an object with agent id and name
      if (id===DropdownIds.ASSIGNEE) { value = { id: optKey, name: optValue }; }
      onChange({ id, value });
    }
    setCurrentValue(optValue);
  }

  // setting initial values for options and current selected value received from parent component
  useEffect(() => {
    setCurrentOptions(options);
  }, [options])
  useEffect(() => {
    setCurrentValue(value);
  }, [value])

  return (
    <>
      <div className="dropdown me-2 rounded-pill text-center dropdownMenuBg my-2" id={ id } key={ id }>
        <button className={ `${disabled ? "disabled" : ""} dropdownMenu dropdownMenuButton` }
         type="button" data-bs-toggle="dropdown"
         style={{ cursor: `${ disabled ? 'no-drop' : 'pointer' }` }}
        >
          { currentVal }
          <span data-tooltip={ currentVal }></span>
        </button>
        <button style={{  cursor: `${ disabled ? 'no-drop' : 'pointer' }` }} type="button"
         className={`dropdownMenu dropdown-toggle dropdown-toggle-split text-center ${ disabled ? 'disabled' : ''}`}
         data-bs-toggle="dropdown" >
        </button>
        <ul className="dropdown-menu" aria-labelledby={id}>
          {
            currentOptions && currentOptions.map((option) => {
              let [optKey, optValue] = [option, option];
              if (id===DropdownIds.ASSIGNEE) {
                optKey = option.id;
                optValue = option.name;
              }
              return (
                <li className="dropdown-item" style={  optValue === currentVal ? { background: '#f3f3f3' } : null}  key={ optKey } onClick={ () => { handleChange({optKey, optValue}); } }>{ optValue }</li>
              )
            })
          }
        </ul>
      </div>
    </>
  )
}

export default DropdownMenu;
