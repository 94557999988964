
import React from 'react';
import AgentAbsence from './AgentAbsence';

export default function AgentAttendaceTracker() {
    return (
        <div className='AgentAttendaceTrackerContainer' >
            <div className='AgentAbsenceContainer' >
                <AgentAbsence/>
            </div>
        </div>
    )
}
