import './ChatListPanel.css';
import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import ChatListItem from "./ChatListItem";
import FiltersMenuBar from "./filters/FiltersMenuBar";
import Loading from "./common/Loading";
import { AppContext } from '../contexts/AppContext';
import APIService from '../services/APIService';
import { Actions } from '../enums/Actions';
import ShowInfoOver from './common/ShowInfoOver';
import ShowInfo from './common/ShowInfo';

// activeList = true is AllChats
// activeList = false is MyChats
function ChatListPanel() {
  const { state, dispatch } = useContext(AppContext);
  const { agent, chatList, currentChat } = state;
  const [activeList, setActiveList] = useState(true);
  const [loading, setLoading] = useState(false);
  const appliedFilters = useRef({});
  const [infiniteLoading, setInfiniteLoading] = useState(false);
  const [noMoreChats, setNoMoreChats] = useState(false);
  const previousTab = useRef(true);

  const getAllChats = useCallback(async () => {
    setActiveList(true);
    setLoading(true);
    
    // check if tab changed and reset filters
    if (!previousTab.current) appliedFilters.current = {};
    previousTab.current = true;

    const payload = await APIService.getChatList({ ...appliedFilters.current });
    if (payload) {
      dispatch({ type: Actions.SET_CHAT_LIST, payload });
    } else {
      dispatch({ type: Actions.SET_CHAT_LIST, payload: [] });
    }
    setLoading(false);
  }, [dispatch]);

  const getMyChats = async () => {
    setActiveList(false);
    setLoading(true);

    // check if tab changed and reset filters
    if (previousTab.current) appliedFilters.current = { agentId: agent.id };
    else appliedFilters.current = { ...appliedFilters.current, agentId: agent.id }
    previousTab.current = false;

    const payload = await APIService.getChatList({ ...appliedFilters.current });
    if (payload) {
      dispatch({ type: Actions.SET_CHAT_LIST, payload });
    } else {
      dispatch({ type: Actions.SET_CHAT_LIST, payload: [] });
    }
    setLoading(false);
  };

  // called when clicked outside after selecting filters
  const getFilteredChats = async ({
    agentId, category, subCategory, status, startDate, endDate,
  }) => {
    appliedFilters.current = {
      agentId, category, subCategory, status, startDate, endDate,
    };
    setLoading(true);
    const payload = await APIService.getChatList({
      agentId, category, subCategory, status, startDate, endDate 
    });
    if (payload) {
      dispatch({ type: Actions.SET_CHAT_LIST, payload });
    } else {
      dispatch({ type: Actions.SET_CHAT_LIST, payload: [] });
    }
    setLoading(false);
  }
  
  // initial loading of chats
  useEffect(() => {
    getAllChats();
  }, [getAllChats])

  // handling new message from websocket
  const handleMessage = useCallback((event) => {
    // function to decide whether to add the received event in the UI or not
    const checkFilters = (onlyChatInfo) => {
      const { 
        agentId, category, subCategory, status, startDate, endDate 
      } = appliedFilters.current;
      let updateList = true;
      // all the conditions are same as implemented in backed when filtered chats are fetched
      if (agentId) {
        if (!onlyChatInfo.agent || onlyChatInfo.agent.id!==agentId) updateList = false;
      }
      if (status && status.length) {
        if (!status.includes(onlyChatInfo.status)) updateList = false;
      }
      if (category && category.length) {
        if (!category.includes(onlyChatInfo.category)) updateList = false;
      }
      if (subCategory && subCategory.length) {
        if (!subCategory.includes(onlyChatInfo.sub_category)) updateList = false;
      }
      if (startDate) {
        const createdAt = new Date(onlyChatInfo.last_incident_at).getTime();
        let start = new Date(startDate);
        start = start.getTime();
        if (createdAt < start) updateList = false;
      }
      if (endDate) {
        const createdAt = new Date(onlyChatInfo.last_incident_at).getTime();
        let end = new Date(endDate);
        end.setDate(end.getDate()+1);
        end = end.getTime();
        if (createdAt >= end) updateList = false;
      }
      return updateList;
    }
    
    const { detail: { action, data } } = event;
    const { last_msg, ...onlyChatInfo } = data;
    let newChatList, newChatInfo;
    // limitation - for AllChats:
    // if there is info update then it will reflect only if the list contains that chat item
    // update chat list
    if (checkFilters(onlyChatInfo)) { // returns if current events should be added in UI or not based on filters
      if (action==='message') { // if message event simply chat list item is inserted at beginning
        newChatList = [data, ...(chatList.filter(l => l.wa_number !== data.wa_number))];
      } else if (action==='info') { // if info event then complexity is more
        let found;
        // first chatlist is checked if received item exist in list. If yes then it is updated
        // else if AllChats is selected then nothing is done
        newChatList = chatList.map(l => {
          if (l.wa_number===data.wa_number) {
            found = true;
            return {
              ...l,
              status: onlyChatInfo.status,
              category: onlyChatInfo.category,
              sub_category: onlyChatInfo.sub_category,
              agent: onlyChatInfo.agent
            }
          }
          return l;
        });
        // When MyChats selected and list does not contain new chat (event received)
        if (
          !found &&
          appliedFilters.current.agentId && 
          appliedFilters.current.agentId === onlyChatInfo.agent?.id
        ) {
          const lastMsgAt = new Date(last_msg.createdAt).getTime();
          let index = 0;
          // find the exact position where the chat item needs to be inserted based on last_msg
          for(index=0; index<newChatList.length; index++) {
            const chat = newChatList[index];
            if (lastMsgAt > new Date(chat.last_msg.createdAt).getTime()) break;
          }
          newChatList = [
            ...(newChatList.slice(0, index)),
            data,
            ...(newChatList.slice(index)),
          ];
        }
      }
    } else { // if filters return false then that chat item is removed from chat list
      // remove that wa_number from screen if it does not satisfy filters anymore
      newChatList = chatList.filter(l => l.wa_number !== data.wa_number);
    }
    // update currently opened chat
    if (currentChat.wa_number===data.wa_number) {
        newChatInfo = onlyChatInfo;
    }
    dispatch({ type: Actions.UPDATE_SOCKET_MESSAGE_AND_STATUS, payload: { 
      chatList: newChatList,
      currentChat: newChatInfo
    }});
  }, [chatList, dispatch, currentChat.wa_number])
  useEffect(() => {
    // adding event listeners for message events and info update events
    document.addEventListener('message', handleMessage);
    document.addEventListener('info', handleMessage);
    return () => {
      document.removeEventListener('message', handleMessage);
      document.removeEventListener('info', handleMessage);
    }
  }, [handleMessage])

  // called when ChatListPanel scroll moves
  const handleScrollChange = async (e) => {
    const { clientHeight, scrollTop, scrollHeight } = e.target;

    // more chats are only fetched when scroll hits bottom (maregin of 0.5 px has been kept)
    if ((clientHeight + scrollTop + 0.5) >= scrollHeight ) {
      // for agent all chats will be fetched at once. So infinite scroll not required.
      // load more chats is not called when on MyChats tab (agentId is present in filters)
      if (!loading && !appliedFilters.current.agentId) {
        loadMoreChats(chatList.length);
      }
    }
  }

  // called when scroll hits the bottom of ChatListPanel
  const loadMoreChats = async (skip=0) => {
    if (skip > 0) {
      setInfiniteLoading(true);
      const data = await APIService.getChatList({ skip, ...appliedFilters.current });
      if (data) {
        if (data.length === 0) {
          setNoMoreChats(true); // If no more chats are fetched then message is shown
          setTimeout(() => { // this message disappears after 2 seconds
            setNoMoreChats(false);
          }, 2000);
        } else {
          // make chatList unique to handle duplicates
          // when new chat created while loadMoreChats already called (skip/limit would have changed)
          // maybe it can be removed later if any better solution is discovered
          // example - maybe try with last_msg._id, but consider thoroughly as it doesnt seem good solution
          // data = [...chatList, ...data] // for testing duplicated
          const waNumExistence = new Set();
          chatList.forEach(el => waNumExistence.add(el.wa_number));
          const filteredData = data.filter(newItem => !waNumExistence.has(newItem.wa_number));
          dispatch({ type: Actions.SET_CHAT_LIST, payload : [...chatList, ...filteredData] });
          // dispatch({ type: Actions.SET_CHAT_LIST, payload : [...chatList, ...data] });
        }
      }
      setInfiniteLoading(false);
    }
  }

  return (
    <>
      <div className="row mt-3 mx-1" id='filterMenuDiv'>
        <FiltersMenuBar data={{ activeChatTab: activeList, getFilteredChats }} />
        <div className='col-12 text-center mt-3 toggleButtonDiv' id='toggleMenuDiv' >
          <div className={ `${activeList ? 'activeChatListBtn ': ''} chatButton col-6` } onClick={getAllChats}>
            All Chats
          </div>
          <div className={ `${!activeList ? 'activeChatListBtn ': ''} chatButton col-6` } onClick={getMyChats}>
            My Chats
          </div>
        </div>
      </div>
      <div onScroll={(e) => handleScrollChange(e)}
        className={`${`row scrollLeftBar chatMenuDiv mt-3 overflow-auto ${loading ? 'chatlistContainerHeight' : 'chatlistContainerMaxHeight'}`}` }>
        {
          loading ? <Loading /> : (
            chatList.length === 0 ? <ShowInfo content="No chats available"/> :
            chatList.map(
              chatListItem => <ChatListItem key={ `chatListItem-${chatListItem.wa_number}` } data={ chatListItem }/>
            )
          )
        }
        {
          infiniteLoading || noMoreChats ? 
            <ShowInfoOver content={noMoreChats ? 'no more chats' : 'Loading...'}/> : null
        }
      </div>
    </>
  );
}

export default ChatListPanel;
