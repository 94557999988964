// all custom methods related to date
const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec'];

const DateService = {};

DateService.dateStr = function (date) {
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    return(`${day} ${months[month]} ${year}`);
}
DateService.dateStrReverseFormat = function (date) {
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    return(`${year}-${month <=9 ? `0${month+1}` : `${month+1}` }-${day}`);
}

DateService.timeStr = function (date) {
    let hours = date.getHours();
    const ampm = hours>=12? 'PM':'AM';
    hours = hours % 12;
    hours = hours? hours: 12;
    const mins = date.getMinutes();
    return(`${hours<10? `0${hours}`:`${hours}`}:${mins<10? `0${mins}`: `${mins}`} ${ampm}`);
}

DateService.dateTimeStr = function (date) {
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours>=12? 'PM':'AM';
    hours = hours % 12;
    hours = hours? hours: 12;
    const mins = date.getMinutes();
    return(`${day} ${months[month]} ${year}, ${hours<10? `0${hours}`:`${hours}`}:${mins<10? `0${mins}`: `${mins}`} ${ampm}`);
}

export default DateService;