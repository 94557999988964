import './ChatMessage.css';
import DateService from '../services/DateService';
import { AutoresponseAgent } from '../enums/Roles';

function ChatMessage(props) {
  const { 
    _id, wa_number, content, createdAt, direction, agent, type, 
    // sub_type,
  } = props.data.message;
  const { index, ref, scrollStopIndex  } = props.data;
  
  // function to convert message into multiline
  // also created hyperlink for multimedia
  const convertToMultiline = (content) => {
    const htmlString = content.split('\n');
    return (
      <>
        {
          // loops over string array after splitting based on newline
          htmlString.map((str, index) => {
            let htmlComponent;
            // if multimedia and caption is there then only first part of message is converted to hyperlink
            if (index===0 && type!=='text') { 
              htmlComponent = <a target="_blank" href={str}>{str}</a>;
            } else {
              // unicode for newline - spaces. Without this only one space was getting rendered
              htmlComponent = str.replace(/ /g, "\u00a0");
            }

            if (index === htmlString.length-1) {
              // To render text with spaces if any, replacing all spaces with &nbsp unicode
              return <span key={index}>{htmlComponent}</span>;
            }
            // To render text with spaces if any, replacing all spaces with &nbsp unicode
            return <span key={index}>{htmlComponent}<br/></span>;
          })
        }
      </>
    )
  }

  return (
    <div id={_id} className='mx-4' ref={ index === scrollStopIndex ? ref : null } >
      <div className={ direction==='IN' ? 'float-start userChat userSpeechBubble p-3 mt-3' : 'agentChat agentSpeechBubble float-end p-3 mt-3' }>
        <span className='chatText'>
          <span className='chatTextDirection'>{ direction==='IN' ? `User ${wa_number? `(${wa_number})`: ''}` : agent?.name||AutoresponseAgent } :</span><br/>
          { convertToMultiline(content) }
        </span>
      </div>
      <span className='clearfix'></span>
      <div>
        <span className={ direction==='IN' ? 'float-start text-secondary' : ' float-end text-secondary' }>
          { DateService.dateTimeStr(new Date(createdAt)) }
        </span>
      </div>
      <span className='clearfix'></span>
    </div>
  );
}

export default ChatMessage;