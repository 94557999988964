import './ChatMessages.css';
import React, { useCallback, useEffect, useState, useRef, useContext } from "react";
import ChatMessage from "./ChatMessage";
import ChatNavBar from "./ChatNavBar";
import Loading from "./common/Loading";
import ShowInfo from './common/ShowInfo';
import SendMessageEditor from "./SendMessageEditor";
import APIService from '../services/APIService';
import { AppContext } from '../contexts/AppContext';
import { ChatStatus } from '../enums/ChatStatus';

function ChatMessages({ customerPhone }) {
  const skip = useRef(0);
  const { state } = useContext(AppContext);
  const { agent, currentChat } = state;
  const [loading, setLoading] = useState(false);
  // messages are not stored in central state as they are required only in this component
  const [messages, setMessages] = useState([]);
  const [showMoveButton, setShowMoveButton] = useState(false);
  const [noMessage, setNoMessage] = useState(false);
  const [infiniteLoading, setInfiniteLoading] = useState(false);
  const [scrollStopIndex, setScrollStopIndex] = useState(0);
  const messageEndRef = useRef();
  const stopScrollRef = useRef();
  
  // scrolls to bottom as soon as a chat list item is clicked and messages are loaded
  const executeScroll = () => {
    if (messageEndRef && messageEndRef.current) messageEndRef.current.scrollIntoView();
  }

  // scrolls to last message when more messages are loaded when scroll hits the top
  const stopScrollToLastMessageIndex = () => {
    if (stopScrollRef && stopScrollRef.current) stopScrollRef.current.scrollIntoView();
  }

  // handles when to show move to bottom button
  const handleScrollChat = (event) => {
    const { clientHeight, scrollTop, scrollHeight } = event.target;
    // move to bottom button shows up when when scroll moves up 60px
    if ((clientHeight + scrollTop) <= scrollHeight) {
      setShowMoveButton(true);
    }
    // for move to bottom button to appear once scroll up the messages
    if (clientHeight + scrollTop >=  (scrollHeight-60)) {
      setShowMoveButton(false);
    }
    if (scrollTop === 0) {
      // when scroll hits top then value of skip is updated so that it can be sent to get further messages
      skip.current = messages.length;
      loadMoreMessages(skip.current);
    }
  }

  // called to fetch more messaged when scroll hits the top
  const loadMoreMessages = async (skip=0) => {
    if (skip > 0) {
      setInfiniteLoading(true);
      const data = await APIService.getChatMessages({ wa_number: customerPhone, skip });
      // order of all these setState calls matter and scroll can happed only after loader has been hidden from UI
      if (!data || data.length === 0) {
        setInfiniteLoading(false); // this has to be here & not in the end
        setNoMessage(true); // show No more messages message and hodes after 2 sec
        setTimeout(() => {
          setNoMessage(false);
        }, 2000);
      } else {
        setScrollStopIndex(data.length); // sets the index of the message where the scroll needs to be taken
        setMessages([...data, ...messages]);
        setInfiniteLoading(false); // this has to be here & not in the end
        stopScrollToLastMessageIndex(); // scrolls to the last message that was visible on tob before more messages were loaded
      }
    }
  }

  useEffect(() => {
    // called as soon as component renders
    // gets initial list of messages
    async function getMessages() {
      setLoading(true);
      const data = await APIService.getChatMessages({ wa_number: customerPhone });
      if (data && data.length) {
        setMessages(data);
        setLoading(false); // this has to be here & not in the end
        executeScroll(); // scrolls to the bottom of chat messages
        skip.current = data.length;
      } else {
        setLoading(false);
      }
    }
    getMessages();
    // cleanup
    return () => {
      setShowMoveButton(false);
      setInfiniteLoading(false);
      setNoMessage(false);
      setMessages([]);
    }
  }, [customerPhone]);

  // handling new message from websocket
  const handleMessage = useCallback((event) => {
    const { data: { last_msg } } = event.detail;
    if (currentChat.wa_number===last_msg.wa_number) {
      // temporary fix for duplicate message events sometimes. Check previous message._id
      if (!(messages[messages.length-1]?._id === last_msg?._id)) {
        setMessages([...messages, last_msg]);
        executeScroll(); // takes scroll to the end when new message arrives
      }
    }
  }, [messages, currentChat.wa_number]);
  useEffect(() => {
    // adding event listener for message events dispatched by Init.js
    document.addEventListener('message', handleMessage);
    return () => {
      document.removeEventListener('message', handleMessage);
    }
  }, [handleMessage])

  return (
    <>
      {
        loading ? <Loading/> : 
        <>
          <ChatNavBar customerPhone={ customerPhone }/>
          <div className="chatMessagesContainer" style={{position:'relative'}}  onScroll={ (e) => handleScrollChat(e) }>
            {/* {
              loadMore?
              <div style={{ zIndex:10, position:'absolute', top:'8px', left:'46%', textAlign:'center' }}>
                <button className='loadMoreButton rounded-pill btn text-white position-fixed'
                onClick={ (e) => loadMoreMessages(e) }>Load more <i class="fas fa-long-arrow-alt-up"></i></button>
              </div> : null
            } */}
            {
              infiniteLoading || noMessage ? 
                <ShowInfo content={noMessage ? 'no more chats' : 'Loading...'}/> : null
            }
            {
              showMoveButton?
              <div className='moveBottomButtonContainer'>
                <button className='moveBottomButton rounded-pill btn text-white position-fixed'
                onClick={ executeScroll }>Move to bottom <i className="fas fa-long-arrow-alt-down ms-1"></i></button>
              </div> : null
            }
            { messages.map((message, index) => <ChatMessage key={ message._id } data={{ message, index, ref: stopScrollRef, scrollStopIndex: scrollStopIndex-1 }}/>) }
            <div ref={messageEndRef}></div>
          </div>
          <SendMessageEditor data={{
            wa_number: customerPhone,
            disabled: (agent?.id !== currentChat.agent?.id) || currentChat.status===ChatStatus.RESOLVED 
          }}/>
        </>
      }
    </>
  );
}

export default ChatMessages;