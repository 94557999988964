import './DisplayPanel.css';
// import './ChatMessages.css';

import { useContext } from 'react';
import SettingsMenuBar from './SettingsMenuBar';
import AgentHome from './home/AgentHome';
import AutoresponseSettings from './autoresponse/AutoresponseSettings';
import AgentAttendance from './attendance/AgentAttendance';
import ChatMessages from './ChatMessages';
import { AppContext } from '../contexts/AppContext'
import { Pages } from  '../enums/Pages';

function DisplayPanel() {
  const { state } = useContext(AppContext);
  const { currentPage } = state;

  let displayPage = null;
  // right side of the app shows based on the currentPage value in global state
  if (currentPage === Pages.AGENT_HOME) {
    displayPage = <AgentHome/>
  } else if (currentPage === Pages.AUTORESPONSE_SETTINGS) {
    displayPage = <AutoresponseSettings/>
  } else if (currentPage === Pages.ATTENDANCE) {
    displayPage = <AgentAttendance/>
  } else { // in this case the currentPage is set to the whatsapp number of the selected chat
    displayPage = <ChatMessages customerPhone={ currentPage } />
  }

  return (
    <div className="row displayPanel">
      <SettingsMenuBar/>
      <div className='displayPanelBody'>
          { displayPage }
      </div>
    </div>
  );
}

export default DisplayPanel;