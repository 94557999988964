
import React from 'react';
import './Common.css';

export default function SimpleHeader({ heading }) {
    return (
        <div className='border-bottom d-flex align-items-center SimpleHeader'>
			<h5 className='ms-3 mb-0'>{ heading }</h5>
		</div>
    )
}
