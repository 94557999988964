
export default function AgentAbsenceMarker(props) {
	const { agent, updateAgentsAttendance } = props.data;
	// component to render the attendance table row
    return (
        <div className='rounded py-3 my-2 d-flex justify-content-between  align-items-center AgentAbsenceMarkerContainer' >
			<div className='px-4'>
				<b className={ agent.absent ? 'AgentAbsenceMarkerName' : 'AgentPresentMarkerName' }>{ agent.name }</b>
			</div>
			<div className='px-4'>
				<input
					checked={ agent.absent }
					onChange={ (e) => updateAgentsAttendance({ ...agent, absent: e.target.checked}) }
					className="form-check-input me-2 mt-0 AgentAbsenceMarkerCheckBox" type="checkbox"
					id={`${agent.id}-flexCheckDefault`} 
				/>
				<label className="form-check-label AgentAbsenceMarkerCheckBoxLabel" htmlFor={`${agent.id}-flexCheckDefault`}>
					<small className={ `${agent.absent ? 'text-primary' : 'text-secondary' } AgentAbsenceMarkerText` }>
						{
							agent.absent ? 'Marked Absent' : 'Mark as Absent'
						}
					</small>
				</label>
			</div>
        </div>
    )
}
