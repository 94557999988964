
import React from 'react';
import APIService from '../../services/APIService';
import './Common.css';

function Unauthorized() {
    return (
        <>
            <div className='d-flex justify-content-center align-items-center mt-4'>
                <span className='verticalLine'></span>
                <h5 className=' ms-3 mt-2'>
                    <b>Access denied - </b>
                    You are not authorized to access this page.
                </h5>
                <div className='ms-3'>
                    <i className="fas fa-arrow-left text-secondary mt-2"></i>
                    <a className="text-secondary ms-2 UnauthorizedLink"
                        href={`${APIService.STAGE==='production' ?
                            'https://vyaparapp.in' : 'http://staging.vyaparapp.in'}`}
                    >
                        GO BACK
                    </a>
                </div>
            </div>
        </>
    );
}

export default Unauthorized
