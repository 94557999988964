import React from 'react';
import './FiltersMenuBar.css';
import FilterDropDownMenu from './FilterDropDownMenu';

function FiltersMenuBar(props) {
  return (
    <div className='col-12'>
        <div className='row'>
            <div className='col-9 col-lg-10 border rounded-pill d-flex align-items-center'
              style={{ cursor: 'no-drop' }} //Search disabled until implemented
            >
              <i className="fas fa-search searchbarIcon" ></i>
              {/* Search disabled until implemented */}
              <input className='m-2 searchbarInput' type='text' placeholder='Search conversations'
                disabled={ true }
                style={{ cursor: 'no-drop' }}
              />
            </div>
            <div className='col-3 col-lg-2 p-0 text-end'>
              <img src='images/filter.svg' id="dropdownMenuFilter" className='filterMenuButtonImg' data-bs-toggle="dropdown" alt='filter setting'/>
              <FilterDropDownMenu data={ props.data } />
            </div>
        </div>
    </div>
  );
}

export default FiltersMenuBar;