
import React from 'react';
import './AutoresponseRow.css';
import { toast } from 'react-toastify';

export default function AutoresponseRowDropdown(props) {
	const { tagName, days, rowStatus, setRowStatus, handleChangeTempState } = props.data;

	// called when startDay dropdown is changed
	const handleStartDayChange = (day, dayIndex) => {
		let obj;
		if (day === 'Weekday' && rowStatus.sendResponse) {
			// if startDay is unchecked(Weekday selected) and sendResponse is selected then sendResponse/closeChat is also unchecked
			obj = {
				...rowStatus,
				startDay: { day, dayIndex },
				endDay: { day, dayIndex },
				sendResponse:false,
				closeChat:false
			}
			setRowStatus(obj)
			handleChangeTempState(obj);
		} else if (day === 'Weekday') {
			// if startDay is unchecked(Weekday selected) then endDay is also set to that
			obj = {
				...rowStatus,
				startDay: { day, dayIndex },
				endDay: { day, dayIndex: 0 }
			}
			setRowStatus(obj)
			handleChangeTempState(obj);
		} else {
			// temp state is updated as per seleced value
			obj = {
				...rowStatus,
				startDay: {day, dayIndex}
			}
			setRowStatus(obj);
			handleChangeTempState(obj);
		}
	}

	// called when endDay dropdown is changed
	const handleEndDayChange = (day, dayIndex) => {
		let obj;
		if (rowStatus.startDay.day === 'Weekday') { // if startDay is not selected then error message shown
			toast.error('Select start day first!');
		} else if (day === 'Weekday' && rowStatus.sendResponse) {
			// if endDay is unchecked(Weekday selected) and sendResponse is selected then sendResponse/closeChat is also unchecked
			obj = {
				...rowStatus,
				startDay: { day, dayIndex },
				endDay: { day, dayIndex },
				sendResponse:false,
				closeChat:false
			}
			setRowStatus(obj)
			handleChangeTempState(obj);
		} else {
			// temp state is updated as per seleced value
			obj = {
				...rowStatus,
				endDay: { day, dayIndex }
			}
			setRowStatus(obj);
			handleChangeTempState(obj);
		}
		
	}
	return (
		<>
		{
			tagName === 'startDay' ? (
			<div className="dropdown d-inline"> 
				<button className={ `${rowStatus.priority%2  === 1 ?
					'AutoresponseRowDayInputEven' :
					'AutoresponseRowDayInputOdd'} dropdown-toggle dropdownButton rounded-pill bg-white  mx-1` }
					type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
						{ rowStatus.startDay.day }
				</button>
				<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
					{
						days.map((day, dayIndex) => <li onClick={ (e) => handleStartDayChange(day, dayIndex) } className="dropdown-item" key={day}>{ day }</li>)
					}
				</ul>
			</div> 
			):
			<div className="dropdown d-inline">
				<button className={ `${rowStatus.priority%2  === 1 ?
					'AutoresponseRowDayInputEven' :
					'AutoresponseRowDayInputOdd'} dropdown-toggle dropdownButton rounded-pill bg-white  mx-1` } 
					type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
						{ rowStatus.endDay.day }
				</button>
				<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
					{
						days.map((day, dayIndex) => <li onClick={ (e) => handleEndDayChange(day, dayIndex) } className="dropdown-item" key={day }>{ day }</li>)
					}
				</ul>
			</div>
		}
		</>
	)
}
