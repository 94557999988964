
import React from 'react';
import './AutoresponseSettings.css';

export default function AutoResponseHeader(props) {
	const { showButtons, handleSaveClick, handleCancelClick  } = props.data;
	const handleSaveAutoresponse = () => {
		handleSaveClick();
	}
	const handleCancelAutoresponse = () => {
		handleCancelClick();
	}
	return (
		<div className='border-bottom d-flex align-items-center justify-content-between AutoResponseHeader'>
			<h5 className='ms-3 mb-0'>Autoresponse Settings</h5>
			<div>
				{
					showButtons ? (
						<>
						<button
							onClick={handleCancelAutoresponse}
							className='text-white py-1 rounded-pill me-3 AutoresponseSettingsButtons'>Cancel
						</button>

						<button
							onClick={handleSaveAutoresponse}
							className='text-white py-1 rounded-pill me-3 AutoresponseSettingsButtons'>Save
						</button>
						</>
					) : null
				}
			</div>
		</div>
	)
}
