export const DropdownDefaults = {
    CATEGORY: 'Category',
    SUB_CATEGORY: 'Sub-Category',
    STATUS: 'Status',
    ASSIGNEE: {
        id: 0,
        name: "Assignee"
    }
};

export const DropdownIds = {
    STATUS: 'status-dropdown',
    CATEGORY: 'category-dropdown',
    SUB_CATEGORY: 'sub-category-dropdown',
    ASSIGNEE: 'agent-dropdown'
};