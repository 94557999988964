
import DateService from "../../services/DateService";
import "./FiltersMenuBar.css";

function FilterSubMenuList(props) {
	const { filterMenu, setFilterMenu, tag, getCategory, setDropdownVisible } = props.data;
	const minDate = '2021-01-01';
	const maxDate = DateService.dateStrReverseFormat(new Date());

	// there is a correlation between startDate and endDate filters

	const toCapitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}
	return (
		<>
		{
			Object.entries(filterMenu).map(([status, value]) => {
				if (tag === 'date') {
					return (
						<li className="dateFilterText dropdown-item float-start" key={ status }>{`${toCapitalize(status)}:`}<br/>
							<input 	type="date" className='filterDateInput'
								value={ value }
								min={ status === 'to' && filterMenu.from !== '' ? filterMenu.from : minDate }
								max={ status === 'from' && filterMenu.to !== '' ? filterMenu.to : maxDate }
								onChange={(e) => {
									setFilterMenu({ ...filterMenu, [status]: e.target.value });
									setDropdownVisible(true);
								}}
							/>
						</li>
					);
				}
				if (tag === 'category') {
					return (
						<li className="dropdown-item d-flex justify-content-between align-items-center me-1" key={ status }>{status}
							<span>
								<input 
									type="checkbox"
									checked={value ? "checked" : ""}
									onChange={(e) => {
										setFilterMenu({ ...filterMenu, [status]: e.target.checked });
										getCategory(status, e.target.checked);
										setDropdownVisible(true);
									}}
									className="form-check-input align-self"
								/>
							</span>
						</li>
					);
				}
				return (
					<li className="dropdown-item d-flex justify-content-between me-1" key={ status }>{status}
						<span>
							<input 
								type="checkbox"
								checked={value ? "checked" : ""}
								onChange={(e) => {
									setFilterMenu({ ...filterMenu, [status]: e.target.checked });
									setDropdownVisible(true);
								}}
								className="form-check-input align-self"
							/>
						</span>
					</li>
				);
			})
		}
		</>
	);
}

export default FilterSubMenuList;
