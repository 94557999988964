
import React, { useState, useEffect, useMemo, useContext } from 'react';
import './AutoresponseSettings.css';
import AutoResponseHeader from './AutoResponseHeader';
import APIService from '../../services/APIService';
import Loading from '../common/Loading';
import AutoresponseRow from './AutoresponseRow';
import './AutoresponseSettings.css';
import { toast } from 'react-toastify';
import { AppContext } from '../../contexts/AppContext';
import { Actions } from '../../enums/Actions';

function AutoresponseSettings() {
  const [showButtons, setShowButtons] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultSize = 7;
  // default setting object
  const defaultSetting = useMemo(() => {
    return {
      _id: null,
      start_time: '00:00',
      end_time: '23:59',
      start_day: 'Weekday',
      end_day: 'Weekday',
      response_text: '',
      send_response: false,
      close_chat: false,
      priority: null
    }
  }, []);
  // initial state as received from DB
  const [originalState, setOriginalState] = useState([]);
  // temp state to maintain the changes in autoresponse settings
  const [tempState, setTempState] = useState([]);
  const { dispatch } = useContext(AppContext);

  // called when save button is clicked after updating autoresponse settings
  const handleSaveClick = async () => {
    // const updatedSettings = tempState.map(setting => {
    //   return  setting.send_response && setting.response_text.trim().length !==0 ?
    //   setting : { ...defaultSetting, priority: setting.priority, _id: setting._id }
    // });
    const updatedSettings = tempState;
    dispatch({ type: Actions.SET_SPINNER_STATUS, payload: true });
    const response = await APIService.saveAutoresponseSettings({
      settings: updatedSettings
    });
    dispatch({ type: Actions.SET_SPINNER_STATUS, payload: false });
    if (response) {
      setTempState(updatedSettings);
      setOriginalState(updatedSettings);
      setShowButtons(false);
      toast.success('Autoresponse settings saved successfully.');
    }
  }
  
  // called when cancel button is clicked. 
  // Temp state is set to the initial state which was obtained from DB.
  const handleCancelClick = () => {
    setTempState(originalState);
    setShowButtons(false);
  }

  // called whenever anything is changed in any row of autoresponse settings. Updates temp state.
  const handleChangeTempState = async (rowData)  => {
    const obj = {
        _id: rowData.id,
        start_day: rowData.startDay.day,
        end_day: rowData.endDay.day,
        start_time: rowData.startTime,
        end_time: rowData.endTime,
        response_text: rowData.responseText,
        send_response: rowData.sendResponse,
        close_chat: rowData.closeChat,
        priority: rowData.priority
      }
    const updatedTempState = tempState.map(ele => ele._id === obj._id ? { ...obj } : { ...ele })
    setTempState(updatedTempState);
  }

  // called as soon as component renders
  useEffect(() => {
    const getSettings =  async () => {
      setLoading(true);
      const settings = await APIService.getAutoresponseSettings();
      if (settings) {
        if (settings.length >= defaultSize) {
          setOriginalState(settings);
          setTempState(settings);
        } else {
          // if number of rows received from DB are less than defaultSize above. 
          // Then new rows are generated with the default setting object above.
          const diff = defaultSize-settings.length;
          let newSettings = [];
          for (let i = 1; i <= diff; i++) {
            newSettings.push({ ...defaultSetting, priority: settings.length+i, _id: settings.length+i });
          }
          newSettings = [...settings, ...newSettings];
          setOriginalState(newSettings);
          setTempState(newSettings);
        }
      }
      setLoading(false);
    }
    getSettings();
  }, [defaultSetting])

  return (
    <div className="AutoresponseSettings bg-white">
      {
        loading ? <Loading/> : (
          <>
            <AutoResponseHeader data={{ showButtons, handleSaveClick, handleCancelClick  }} />
            <div className='AutoresponseSettingsBodyDiv' >
              <div className='m-3 border tableContainerDiv'>
                <table className='AutoresponseSettingsTable'>
                  <tbody>
                    {
                      tempState.length !== 0 && tempState.map( 
                        autoRow => (autoRow._id && autoRow.priority) && 
                          <AutoresponseRow key={ autoRow._id } 
                            data={{ autoRow, handleChangeTempState, setShowButtons }} 
                          />
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )
      }
    </div>
  );
}

export default AutoresponseSettings;