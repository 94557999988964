
import './Common.css';
function Loading() {
  return (
    <div className="Loading row" >
      <div className="col-12">
        <div className="spinner-border LoadingSpinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
}

export default Loading;