import './ChatNavBar.css';
import { useEffect, useState, useMemo, useContext} from 'react';
import { AppContext } from '../contexts/AppContext'
import DropdownMenu from './DropdownMenu';
import { ChatStatus } from '../enums/ChatStatus';
import { DropdownDefaults, DropdownIds } from '../enums/DropdownDefaults';
import APIService from '../services/APIService';
import { toast } from 'react-toastify';
import { Actions } from '../enums/Actions';
import { Roles } from '../enums/Roles';

function ChatNavBar() {
	const { state, dispatch } = useContext(AppContext);
	const { categories, agents, currentChat } = state;
	const notAdmin = state.agent?.role!==Roles.ADMIN;
	const { wa_number, category, sub_category, agent, status } = currentChat;
	const [dirty, setDirty] = useState(false); // used to decide when to show save and cancel buttons
	// setting initial state based on values received from database
	const initialOptions = useMemo(() => {
		let subCategoryOptions;
		if (!category || category===DropdownDefaults.CATEGORY) {
			subCategoryOptions = [DropdownDefaults.SUB_CATEGORY];
		} else {
			const categoryObj = categories.find(c => c.category===category);
			if (categoryObj) {
				subCategoryOptions = [
					DropdownDefaults.SUB_CATEGORY, 
					...categoryObj.sub_categories
				];
			} else {
				// probably category got deleted
				subCategoryOptions = [DropdownDefaults.SUB_CATEGORY];
			}
		}

		return {
			status: status ? (
					status===ChatStatus.RESOLVED ? 
						[ChatStatus.RESOLVED] : [status, ChatStatus.RESOLVED]
				): [DropdownDefaults.STATUS, ChatStatus.RESOLVED],
			categories: [DropdownDefaults.CATEGORY, ...categories.map(c => c.category)],
			subCategories: subCategoryOptions,
			agents: [DropdownDefaults.ASSIGNEE, ...agents]
		}
	}, [status, category, agents, categories]);
	const [options, setOptions] = useState(initialOptions);
	// setting initial options based on values received from database
	const initialValues = useMemo(() => {
		return {
			[DropdownIds.STATUS]: status? status: DropdownDefaults.STATUS,
			[DropdownIds.CATEGORY]: category? category : DropdownDefaults.CATEGORY,
			[DropdownIds.SUB_CATEGORY]: sub_category? sub_category : DropdownDefaults.SUB_CATEGORY,
			[DropdownIds.ASSIGNEE]: (agent && agent.id)? agent : DropdownDefaults.ASSIGNEE,
		}
	}, [status, category, sub_category, agent]);
	const [tempState, setTempState] = useState(initialValues);
	// when a non-category dropdown is changed then only the value is updated in the temp state
	const handleNonCategoryChange = ({ id, value }) => {
		setDirty(true);
		setTempState({
			...tempState,
			[id]: value
		});
	}

	// temp state and option are set to initial state and initial options if cancel is called
	const handleCancel = () => {
		setOptions(initialOptions);
		setTempState(initialValues);
		setDirty(false);
	}

	// if category dropdown is changed then subcategory options are updated in temp options
	// sub-categories have a dependency on categories
	const handleCategoryChange = ({ id, value }) => {
		setDirty(true);
		const subCats = (value===DropdownDefaults.CATEGORY)? 
			[]: categories.find(c => c.category===value).sub_categories;
		setOptions({...options, subCategories: [DropdownDefaults.SUB_CATEGORY, ...subCats]});
		setTempState({
			...tempState,
			[id]: value,
			[DropdownIds.SUB_CATEGORY]: DropdownDefaults.SUB_CATEGORY
		});
	}

	// takes the values in tempState and calls the API
	const saveIncidentInfo = async () => {
		const category = tempState[DropdownIds.CATEGORY]===DropdownDefaults.CATEGORY ?
			null: tempState[DropdownIds.CATEGORY];
		const sub_category = tempState[DropdownIds.SUB_CATEGORY]===DropdownDefaults.SUB_CATEGORY ? 
			null: tempState[DropdownIds.SUB_CATEGORY];
		const status = tempState[DropdownIds.STATUS]===DropdownDefaults.STATUS ? 
			null: tempState[DropdownIds.STATUS];
		const agent = (tempState[DropdownIds.ASSIGNEE]?.id===DropdownDefaults.ASSIGNEE.id ||
			tempState[DropdownIds.STATUS]===ChatStatus.RESOLVED) ?
			null: tempState[DropdownIds.ASSIGNEE];
		const payload = {
			wa_number, category, sub_category, status, agent
		}
		if (status==='Resolved' && (!category || !sub_category)) {
			// If status is being marked Resolved then category/subcategory must be set
			toast.error('Please specify Category/Sub-Category for Resolving.');
		} else {
			dispatch({ type: Actions.SET_SPINNER_STATUS, payload: true });
			const response = await APIService.saveIncidentInfo(payload);
			dispatch({ type: Actions.SET_SPINNER_STATUS, payload: false });
			if (response) {
				// dispatch({ // no need for this dispatch as backend API will send updates to all
				// 	type: Actions.UPDATE_CHAT_INFO,
				// 	payload
				// });
				setDirty(false);
				toast.success('Info saved successfully.');
			}
		}
	}

	useEffect(() => {
		// sets initila option to temp options
		setOptions(initialOptions) 
	}, [initialOptions])
	useEffect(() => {
		// sets initial values to temp state
		setTempState(initialValues) 
	}, [initialValues])

	return (
		<div className='border col-12 chatNavBar py-1' >
			<div className='d-flex justify-content-lg-between justify-content-md-evenly flex-wrap align-items-center'>
				<div className='chatWaNumber'>
					<span>{ wa_number }</span>
				</div>
				<div className='searchMessageBox my-2'
					style={{ cursor: 'no-drop' }} //Search disabled until implemented
				>
					<i className="fas fa-search searchMessageBoxIcon" aria-hidden="true"></i>
					{/* Search disabled until implemented */}
					<input className='searchMessageBoxInput' type='text' placeholder='Search messages in this conversation'
						disabled={ true }
						style={{ cursor: 'no-drop' }}
					/>
				</div>
			</div>
			<div className='d-flex justify-content-lg-between justify-content-md-evenly flex-wrap align-items-center'>
				<div className='d-flex'>
					<DropdownMenu data={{ 
						id: DropdownIds.STATUS,
						options: options.status,
						value: tempState[DropdownIds.STATUS],
						disabled: (
							status===ChatStatus.RESOLVED ||
							(notAdmin && state.agent?.id !== currentChat.agent?.id)
						),
						onChange: handleNonCategoryChange,
					}}/>
					<DropdownMenu data={{ 
						id: DropdownIds.CATEGORY,
						options: options.categories,
						value: tempState[DropdownIds.CATEGORY],
						disabled: (
							status===ChatStatus.RESOLVED ||
							(notAdmin && state.agent?.id !== currentChat.agent?.id)
						),
						onChange: handleCategoryChange,
					}}/>
					<DropdownMenu data={{ 
						id: DropdownIds.SUB_CATEGORY,
						options: options.subCategories,
						value: tempState[DropdownIds.SUB_CATEGORY],
						disabled: (
							status===ChatStatus.RESOLVED ||
							(notAdmin && state.agent?.id !== currentChat.agent?.id)
						),
						onChange: handleNonCategoryChange,
					}}/>
					<DropdownMenu data={{ 
						id: DropdownIds.ASSIGNEE, 
						options: options.agents,
						value: tempState[DropdownIds.ASSIGNEE].name,
						disabled: (
							status===ChatStatus.RESOLVED ||
							notAdmin
							// (notAdmin && state.agent?.id !== currentChat.agent?.id)
						),
						onChange: handleNonCategoryChange,
					}}/>
				</div>
				<div>
					{
						dirty ? (
							<>
								<button className='text-white saveButton mx-2' onClick={ handleCancel }>Cancel</button>
								<button className='text-white cancelButton' onClick={ saveIncidentInfo }>Save</button>
							</>
						) : <></>
					}
					
				</div>
			</div>
		</div>
	);
}



export default ChatNavBar;