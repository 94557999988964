
import React, { useState, useEffect,useRef, useContext } from 'react';
import FilterSubMenuListItem from './FilterSubMenuListItem';
import ClearFilterSubMenu from './ClearFilterSubMenu';
import './FiltersMenuBar.css';
import { AppContext } from '../../contexts/AppContext';
import { ChatStatus } from '../../enums/ChatStatus';


// custom hook to handle dropdown outside click event
const useOutsideClick = (dropdownVisible) => {
    const handleRef = useRef();
    const [sendRequest, setSendRequest] = useState(false);

  const updateSendRequest = () => {
    setSendRequest(false);
  }
  const handleClick = (e) => {
    if (!handleRef.current.contains(e.target)) {
      if (dropdownVisible) {
        setSendRequest(true);
      }
    }
  }
  useEffect(() => {
    document.addEventListener('click',handleClick);
    return () => {
      document.removeEventListener('click',handleClick);
    }
  })
  return { handleRef, sendRequest, updateSendRequest }
}

function FilterDropDownMenu(props) {
  
  // to convert array into state object to use as state obj
  const toObject = (arr) => {
    let obj = {};
    for(let i=0; i <arr.length; i++) {
      obj[arr[i]] = false;
    }
    return obj;
  }
  // to reset filter obj, and all value to false
  const toFalseObj = (obj) => {
    for (let key in obj) {
      obj[key] = false;
    }
    return obj;
  }

  const { state } = useContext(AppContext);
  const { agent, categories } = state;
  // activeChatTab == true mean allchat tab is on || activeChatTab == false mean mychat tab is on
  const { activeChatTab,  getFilteredChats } = props.data; 
  const categoryList = categories.map(c => c.category);
  const statusList = Object.entries(ChatStatus).map(([key, value]) => value);

  // converting array filter into state object
  const statusFilterObj = toObject(statusList);
  const categoryFilterObj = toObject(categoryList);

  // filters state
  const [statusFilter, setStatusFilter] = useState(statusFilterObj);
  const [categoryFilter , setCategoryFilter] = useState(categoryFilterObj);
  const [subCategoryFilter, setSubCategoryFilter] = useState({});
  const [dateFilter, setDateFilter] = useState({ from: '', to: '' });

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { handleRef, sendRequest, updateSendRequest } = useOutsideClick(dropdownVisible);

  // functions to clear particular filters
  const resetStatusFilter = () =>{
    setStatusFilter({...toFalseObj(statusFilter)});
  }
  const resetCategoryFilter = () =>{
    setCategoryFilter({...toFalseObj(categoryFilter)});
    setSubCategoryFilter({});
  }
  const resetSubCategoryFilter = () =>{
    setSubCategoryFilter({...toFalseObj(subCategoryFilter)});
  }
  const resetDateFilter = () => {
    setDateFilter({ from: '', to: '' });
  }
  // to reset all filters
  const resetAllFilter = () =>{
    resetStatusFilter();
    resetCategoryFilter();
    resetDateFilter();
    setDropdownVisible(true);
  }
  const getSelectedValues = (obj) => {
    const arr = [];
    for (let key in obj) {
      if (obj[key]) { arr.push(key); }
    }
    return arr;
  }
  useEffect(() => {
    // if sendRequest (as returned from custom hook for outside click) is true then request is sent to get filtered chats
    if (sendRequest) {
      async function getFilteredChatList(){
        // filter on all chats
        if (activeChatTab) {
          getFilteredChats({
            category: getSelectedValues(categoryFilter), 
            subCategory: getSelectedValues(subCategoryFilter),
            status: getSelectedValues(statusFilter),
            startDate: dateFilter.from,
            endDate: dateFilter.to
          })
          updateSendRequest(false);
          setDropdownVisible(false);
        }
        else{
          // filter on my chats
          getFilteredChats({
            agentId: agent.id,
            category: getSelectedValues(categoryFilter), 
            subCategory: getSelectedValues(subCategoryFilter),
            status: getSelectedValues(statusFilter),
            startDate: dateFilter.from,
            endDate: dateFilter.to
          })
          updateSendRequest(false);
          setDropdownVisible(false);
        }
      }
      getFilteredChatList();
    }
  }, [sendRequest]);

  useEffect(() => {
    // on chat tab change cleanup function
    setDropdownVisible(false)
    return () => {
      resetAllFilter();
    }
  }, [activeChatTab])
  
  // method to create sub-category filter state dynamically on selecting category
  const getCategory = (categorySelect, checked) => {
    if (checked) {
      let subFilter = categories.find(cat => cat.category === categorySelect);
      subFilter = toObject(subFilter.sub_categories);
      setSubCategoryFilter({...subCategoryFilter, ...subFilter});
    }
    else{
      let removeFilter = categories.find(cat => cat.category === categorySelect);
      removeFilter = toObject(removeFilter.sub_categories);
      // to avoid delete keyword we are not using below logic :
      // let sub  = { ...subCategoryFilter }; 
      // Object.keys(removeFilter).forEach(key =>  delete sub[key]);
      // setSubCategoryFilter(sub);
      let sub  = {};
      Object.keys(subCategoryFilter).forEach(key =>  {
        if (!removeFilter.hasOwnProperty(key)) {
          sub[key] = subCategoryFilter[key]
        }
      });
      setSubCategoryFilter(sub);
    }
  }
  
  return (
    <>
      <ul className="dropdown-menu filterMenuList" aria-labelledby="dropdownMenuFilter" onClick={(e) => e.stopPropagation()}  ref={ handleRef }>

        <li className="dropdown-item float-start">Status <i className="float-end fas fa-angle-right text-secondary mt-1"></i>
          <ul  className='dropdown2 m-0 p-0' id='dropdown2'>
            <FilterSubMenuListItem data={{ filterMenu: statusFilter, setFilterMenu: setStatusFilter, tag: 'status', setDropdownVisible }} />
            <ClearFilterSubMenu onClick={ (e) => {
              resetStatusFilter();
              setDropdownVisible(true);
            }}/>
          </ul>
        </li>

        <li className="dropdown-item  float-start">Category <i className="float-end fas fa-angle-right text-secondary mt-1"></i>
          <ul className='dropdown2 m-0 p-0 ' >
            <FilterSubMenuListItem data={{ filterMenu: categoryFilter, setFilterMenu: setCategoryFilter, tag: 'category', getCategory, setDropdownVisible }}/>
            <ClearFilterSubMenu onClick={ (e) => {
              resetCategoryFilter();
              setDropdownVisible(true);
            }}/>
          </ul>
        </li>

        <li className="dropdown-item  float-start" >Sub-category <i className=" float-end fas fa-angle-right text-secondary mt-1"></i>
          <ul className='dropdown2 m-0 p-0 '>
          <FilterSubMenuListItem data={{ filterMenu: subCategoryFilter, setFilterMenu: setSubCategoryFilter, tag: 'subCategory', setDropdownVisible }}/>
          {
            Object.keys(subCategoryFilter).length === 0 ? <p className='text-secondary text-center  m-auto py-2'>Select Category</p> :
            <ClearFilterSubMenu onClick={ (e) => {
              resetSubCategoryFilter();
              setDropdownVisible(true);
            }}/>
          }
          </ul>
        </li>

        <li className="dropdown-item  float-start">Date <i className=" float-end fas fa-angle-right text-secondary mt-1"></i>
          <ul className='dropdown2 m-0 p-0 '>
            <FilterSubMenuListItem data={{ filterMenu: dateFilter, setFilterMenu: setDateFilter, tag: 'date', setDropdownVisible }} />
            <ClearFilterSubMenu onClick={ (e) => {
              resetDateFilter();
              setDropdownVisible(true);
            }}/>
          </ul>
        </li>
        <li className="dropdown-item text-center text-primary " onClick={ resetAllFilter }>clear all </li>
      </ul>
    </>
  )
}

export default FilterDropDownMenu;
